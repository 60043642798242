import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import numeral from 'numeral';
import { format } from 'date-fns';

//New versión
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//CSS
import { alpha } from '@mui/material/styles';

const SERVER_SETTTINGS = window.Settings;
const commint = 72;
//const Environment = true;
const HOURS_PER_DAY = 10;
const DAYS_PER_WEEK = 7;
const DAYS_PER_MONTH = 30;

const DAY_START_TIMES = '09:00';

const typedtime = [
    { ID: "mid", description: "Medio día", keyfield: 'parttime', icon: <LocalOfferIcon/>, setting: {day: 0, hours: (HOURS_PER_DAY / 2)}},
    { ID: "day", description: "Día", keyfield: 'price', icon: <LocalOfferIcon/>, setting: {day: 1, hours: HOURS_PER_DAY}},
    { ID: "week", description: "Semana", keyfield: 'week', icon: <LocalOfferIcon/>, setting: {day: DAYS_PER_WEEK, hours: (HOURS_PER_DAY*DAYS_PER_WEEK)}},
    { ID: "month", description: "Mes", keyfield: 'month', icon: <LocalOfferIcon/>, setting: {day: DAYS_PER_MONTH, hours: (HOURS_PER_DAY*DAYS_PER_MONTH)}},
];

const divisas = [
    { code: "EUR", description: "Euro", decoration: "€", ID: "euro", locales: "es-ES" },
    { code: "USD", description: "Dólar estadounidense", decoration: "$", ID: "dollar", locales: "es-ES" },
    { code: "GBP", description: "Libra esterlina", decoration: "£", ID: "pound", locales: "es-ES" },
    { code: "CHF", description: "Franco suizo", decoration: "CHF", ID: "swiss_franc", locales: "es-ES" },
    { code: "JPY", description: "Yen japonés", decoration: "¥", ID: "yen", locales: "es-ES" },
    { code: "CAD", description: "Dólar canadiense", decoration: "CAD", ID: "canadian_dollar", locales: "es-ES" },
    { code: "AUD", description: "Dólar australiano", decoration: "AUD", ID: "australian_dollar", locales: "es-ES" },
    { code: "CNY", description: "Yuan chino", decoration: "CNY", ID: "chinese_yuan", locales: "es-ES" },
    { code: "HKD", description: "Dólar de Hong Kong", decoration: "HKD", ID: "hong_kong_dollar", locales: "es-ES" },
    { code: "NZD", description: "Dólar neozelandés", decoration: "NZD", ID: "new_zealand_dollar", locales: "es-ES" }
];

const LANGS = [
  {
    label: 'Español',
    value: 'es',
    systemValue: null,
    icon: 'https://flagcdn.com/w40/es.png',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: null,
    icon: 'https://flagcdn.com/w40/us.png',
  }
]

const VARS = {
  version: '1.3.'+commint,
  idbusiness: 1,
  name: SERVER_SETTTINGS?.companyname??'',
  RATE_BASE_BOAT: parseFloat(SERVER_SETTTINGS?.base_entity??'0'),
  RATE_BASE_BOOKING: parseFloat(SERVER_SETTTINGS?.base_booking??'0'),
  RATE_BASE_SERVICE: parseFloat(SERVER_SETTTINGS?.base_services??'0'),
  RATE_BASE_SKIPPER: parseFloat(SERVER_SETTTINGS?.base_skipper??'0'),
  DISABLED_SKIPPERS: parseInt(SERVER_SETTTINGS?.disabled_functions_skipper??'0') === 0,
  localhost: "https://ulaboat.com/",
  host:  "https://api.ulaboat.com/",
  hostMessages: "https://api.ulaboat.com",
  Authorization: SERVER_SETTTINGS?.frontend_authorization??'',
  ApiKeyMap: SERVER_SETTTINGS?.frontend_apikeymap??'',
  lang: SERVER_SETTTINGS?.frontend_lang??'es',
  AllowedEditHours: parseInt(SERVER_SETTTINGS?.frontend_allowededithours??'24'),
  AllowedDepositHours: parseInt(SERVER_SETTTINGS?.frontend_alloweddeposithours??'48'),
  uploads: "uploads/",
  cache: "cache/",
  country: 66,
  termsAndConditions: SERVER_SETTTINGS?.frontend_url_terms??"!#",
  privacyPolicies: SERVER_SETTTINGS?.frontend_url_policies??"!#",
  cancelPolicies: SERVER_SETTTINGS?.frontend_url_policies_cancel??"!#",
  MAX_SIZE_FILE_IN_BYTES: parseInt(SERVER_SETTTINGS?.frontend_max_size_file??'200000000'),
  ALLOWSEXT: SERVER_SETTTINGS?.frontend_allowed_exts??".jpg,.png,.jpeg",
  divisaCurrent:SERVER_SETTTINGS?.frontend_divisa?? "euro",
  google_enabled: parseInt(SERVER_SETTTINGS?.google_enabled??'0')===1,
  google_client_id: SERVER_SETTTINGS?.client_id_google,
  apple_enabled: parseInt(SERVER_SETTTINGS?.apple_enabled??'0')===1,
  apple_client_id: SERVER_SETTTINGS?.client_id,
  apple_redirect_uri: SERVER_SETTTINGS?.redirect_uri,
  divisas: divisas,
  discount: [
    { ID: "fijo", description: "Fijo", decoration: "" },
    { ID: "porcentaje", description: "Porcentaje", decoration: "%" },
  ],
  typedtime: typedtime,
  start_times: DAY_START_TIMES,
  LANGS: LANGS,
  HEADER: {
    MOBILE_HEIGHT: 58,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 66,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 66 - 22,
  },
  NAVBAR: {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 225,
    DASHBOARD_COLLAPSE_WIDTH: 78,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
  },
  ICON: {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
  },
  status: (status) => {
    switch (status) {
      case "publish":
        return "Publicado";
      case "draft":
        return "Borrador";
      case "archive":
        return "Archivado";
      case "trash":
        return "Papelera";
      case "deleted":
        return "Eliminado";
      case "private":
        return "Privado";
      default:
        return "";
    }
  },
  statusColor: (status) => {
    switch (status) {
      case "publish":
      case "accepted":
        return "primary";
      case "unconfirmed":      
      case "archive":
        return "warning";
      case "rejected":
        return "error";
      case "trash":
        return "danger";
      case "deleted":
      case "private":  
        return "secondary";
      default:
        return "warning";
    }
  },
  getDateFormat: (d, h) => {
    if(d === null){
      return 'DD/MM/YYYY HH:MM';
    }
    let $d = new Date(d+'T00:00:00');
    let $d1 = ($d.getDate() < 10 ? '0'+$d.getDate(): $d.getDate());
    let $m1 = (($d.getMonth()+1) < 10 ? '0'+($d.getMonth()+1): ($d.getMonth()+1));
    let $y1 = $d.getFullYear();
    return $d1 +'/'+ $m1 +'/'+ $y1+' '+(h === null ? 'HH:MM': h);
  },
  formatMoneyEntity: (price, clean) => {
    //const price = prices !== undefined ? (prices[0] !== undefined ? prices[0] : {}) : {};

    const day = parseFloat(price?.price ?? "0.00");
    //const parttime = parseFloat(price?.parttime ?? "0.00");
    //const week = parseFloat(price["1week"] ?? "0.00");
    //const month = parseFloat(price["1month"] ?? "0.00");
    const divisa = divisas.find((divisa) => divisa.ID === (price?.divisa ?? "euro"));
    let minValue, minVariable;

    //if (day > 0) {
      minValue = day;
      minVariable = typedtime[1]?.description;
    /*} else {
      minValue = Math.min(parttime, week, month);
      if (minValue === parttime) {
        minVariable = typedtime[0]?.description;
      } else if (minValue === week) {
        minVariable = typedtime[2]?.description;
      } else {
        minVariable = typedtime[3]?.description;
      }
    }*/
    const pricebuy = parseFloat(minValue).toLocaleString(divisa?.locales, { style: "currency", currency: divisa?.code });

    if(clean){
      return pricebuy;
    }

    return <span className="price">
        <b>{pricebuy}</b><span>{" / "}{minVariable}</span>
    </span>
  },
  price_service: (p, v, t, d) => {
    const price_value = (prices, typed) => {
      const price_value = prices[typed?.keyfield]??"0";
      const price_base = !isNaN(parseFloat(price_value))?parseFloat(price_value): 0;

      if(typed?.ID !== 'day' && price_base === 0){
        const price_default = prices['price']??"0";
        return !isNaN(parseFloat(price_default))?parseFloat(price_default): 0;
      }

      return price_base;
    }
    const price_base = price_value(p, t);

    let price = 0;
    switch (v) {
      case "1"://En la reserva (Pago único),
      case "3"://En el puerto (Pago único),
        price = price_base;
        break;
      case "4"://Gratis
        price = parseFloat("0.00");
        break;
      case "2"://En el puerto (Pago jornada),
      default: //Por jornada,
        if(t?.ID !== 'day' && (parseFloat(price_base) !== parseFloat(p?.price))){
          const settings_days = t?.setting?.day;
          price = ((settings_days>0)?(price_base/settings_days):price_base) * d;
        }else{
          price = price_base * d;
        }
        break;
    }
    return (price);
  },
  getPriceService: (prices, typed) => {
    const KeyID = (typed?.ID??"day");
    //const KeyReturn = (typed?.keyfield??"price");
    //const Description = (typed?.description??typedtime[1]?.description);
    const price_enabled = (prices!==undefined?(prices.find((price) => price?.typed === KeyID)??{}):{})
    const price = price_enabled?.enabled === 1 ? price_enabled: (prices!==undefined?(prices.find((price) => price?.typed==="day")??{}):{});

    return price;
  },
  formatMoneyService: (prices, typed, full) => {
    const KeyID = (typed?.ID??"day");
    const KeyReturn = (typed?.keyfield??"price");
    const Description = (typed?.description??typedtime[1]?.description);
    const price_enabled = (prices!==undefined?(prices.find((price) => price?.typed === KeyID)??{}):{})
    const price = price_enabled?.enabled === 1 ? price_enabled: (prices!==undefined?(prices.find((price) => price?.typed==="day")??{}):{});

    const price_value = parseFloat(price[(price_enabled?.enabled===1?KeyReturn:"price")]??"0.00");
    const divisa = divisas.find((divisa) => divisa.ID === (price?.divisa ?? "euro"));

    if(full){
      return price_value;
    }

    const price_format = parseFloat(price_value).toLocaleString(divisa?.locales, { style: "currency", currency: divisa?.code });
    return <span className="price">
        <b>{price_format}</b><span>{" / "+(price_enabled?.enabled===1?Description:typedtime[1]?.description)}</span>
    </span>
  },
  tagPriceService: (s) => {
    switch (s?.ID) {
      case '0': 
      case '2': return "Día";
      case '1': 
      case '3': return "Reserva";
      default: return s?.description??""
    }
  },
  formatMoney: (value, code) => {
    const divisa = divisas.find((divisa) => divisa.ID === (code ?? "euro"));
    return parseFloat((isNaN(parseFloat(value))? "0.00": parseFloat(value))).toLocaleString(divisa?.locales, { style: "currency", currency: divisa?.code });
  },
  formatMoneyInput: (value, code) => {
    const parsedValue = parseFloat(value.replace(/[^\d.-]/g, "")) || 0;
    const formattedValue = parsedValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,      
      useGrouping: false
    });
    return formattedValue;
  },
  getToken: () => {
    const Ulaboatsession = (localStorage.getItem('Ulaboat-session'))
    let user = {}
    try {
      user = Ulaboatsession !== null ? JSON.parse(Ulaboatsession) : {};
    } catch (error) {
    }
    return user?.logkey??""
  },
  useResponsive: (query, key, start, end) => {
    const theme = useTheme();
  
    const mediaUp = useMediaQuery(theme.breakpoints.up(key));
  
    const mediaDown = useMediaQuery(theme.breakpoints.down(key));
  
    const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  
    const mediaOnly = useMediaQuery(theme.breakpoints.only(key));
  
    if (query === 'up') {
      return mediaUp;
    }
  
    if (query === 'down') {
      return mediaDown;
    }
  
    if (query === 'between') {
      return mediaBetween;
    }
  
    if (query === 'only') {
      return mediaOnly;
    }
    return null;
  },
  cssStyles: (theme) => {
    const getDirection=(value = 'bottom') => {
      return {
        top: 'to top',
        right: 'to right',
        bottom: 'to bottom',
        left: 'to left',
      }[value];
    }
    return {
      bgBlur: (props) => {
        const color = props?.color || theme?.palette.background.default || '#000000';
  
        const blur = props?.blur || 6;
        const opacity = props?.opacity || 0.8;
  
        return {
          backdropFilter: `blur(${blur}px)`,
          WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
          backgroundColor: alpha(color, opacity),
        };
      },
      bgGradient: (props) => {
        const direction = getDirection(props?.direction);
        const startColor = props?.startColor || `${alpha('#000000', 0)} 0%`;
        const endColor = props?.endColor || '#000000 75%';
  
        return {
          background: `linear-gradient(${direction}, ${startColor}, ${endColor});`,
        };
      },
      bgImage: (props) => {
        const url = props?.url || 'https://minimal-assets-api.vercel.app/assets/images/bg_gradient.jpg';
        const direction = getDirection(props?.direction);
        const startColor = props?.startColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);
        const endColor = props?.endColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);
  
        return {
          background: `linear-gradient(${direction}, ${startColor}, ${endColor}), url(${url})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        };
      },
    };
  },
  fDate: (date) => {
    return format(new Date(date), 'dd MMMM yyyy');
  },
  fPercent: (number) =>  {
    return numeral(number / 100).format('0.0%');
  },
  fNumber: (number) => {
    return numeral(number).format();
  },
  fShortenNumber: (number) => {
    return numeral(number).format('0.00a').replace('.00', '');
  },
  fDateTime: (date) => {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  },
  fDateTimeShort: (date) => {
    return format(new Date(date), 'dd MMM yyyy');
  },
  fData: (number) => {
    return numeral(number).format('0.0 b');
  },
  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  cleanText: (str) => {
    const htmlEntities = {
      '&aacute;': 'á',
      '&eacute;': 'é',
      '&iacute;': 'í',
      '&oacute;': 'ó',
      '&uacute;': 'ú',
      '&ntilde;': 'ñ',
      '&Aacute;': 'Á',
      '&Eacute;': 'É',
      '&Iacute;': 'Í',
      '&Oacute;': 'Ó',
      '&Uacute;': 'Ú',
      '&Ntilde;': 'Ñ',
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&apos;': "'",
      '&iexcl;': '¡',
      '&iquest;': '¿'
    };
    return str.replace(/&[a-zA-Z]+;/g, (entity) => htmlEntities[entity] || entity);
  }
};
export default VARS;